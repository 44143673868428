<template>
  <div style="background-color: #00A8BB">
    <v-container
      fill-height
      fluid
      grid-list-xl
    >
      <v-container
          fluid
          grid-list-lg
      >
        <v-layout
            justify-center
            wrap
        >
          <div class="direita">
            <a href="http://enercred.com.br" target="_blank" alt="Enercred - Energia Limpa e Barata"><img src="../../assets/logo-enercred-branco.png" width="300px" style="padding: 5px" alt="Enercred - Energia Limpa e Barata" class="src"></a>
            <div class="header">
                <span class="font-weight-black" >Olá, {{ first_name }}!</span>
                <div style="color:#FFF">Está preparado(a) para ter uma economia de aproximadamente R${{ desc_cont }}?<br>
                Precisamos agora que leia atentamente o contrato a seguir antes de finalizar sua adesão.<br><br></div>
            </div>
          </div>
          <div id='signature-div'></div>
          <v-snackbar
          :color="color"
          :bottom="bottom"
          :top="top"
          :left="left"
          :right="right"
          v-model="snackbar"
          dark
          >
          <v-icon
              color="white"
              class="mr-3"
          >
              mdi-bell-plus
          </v-icon>
          <div>{{snackMessage}}</div>
          <v-icon
              size="16"
              @click="snackbar = true"
          >
              mdi-close-circle
          </v-icon>
          </v-snackbar>
      </v-layout>
      </v-container>
    </v-container>
  </div>
</template>
<script>
import { getLead, updateLead } from '../../services/leads'
import { SET_CONTRACT } from '../../graphql/contracts'

export default {
  data: () => ({
    loading: true,
    lead: '',
    first_name: '',
    desc_cont: 0,
    state: {},
    contrato: {},
    color: null,
    colors: [
      'purple',
      'info',
      'success',
      'warning',
      'error'
    ],
    top: true,
    bottom: false,
    left: false,
    right: false,
    snackMessage: '',
    snackbar: false,
    x: 0,
    y: 0,
    /* estadoCivil: ['Solteiro(a)', 'Casado(a)', 'Viúvo(a)', 'Separado(a) Judicialmente', 'Divorciado(a)'],
    regimeBens: ['Comunhão Parcial', 'Comunhão Universal', 'Participação Final nos Aquestos', 'Separação de Bens'], */
    processing: false,
    /*rules: {
      birthday: {
        required: v => !!v || 'Por favor, insire uma data de nascimento!',
        regex: v => /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/.test(v) || 'Data de nascimento inválida!'
      }
    }*/
  }),
  beforeDestroy () {
    this.processing = false;
  },
  methods: {
    validate () {
      if (this.$refs.form.validate()) {
        this.finalizar(this.lead)
      }
    },
    finalizar: function(lead) {
      this.processing = true;
      lead.marital_status = this.marital_status;
      /* updateLead(this.$route.query.email, lead)
        .then((result) => {
          
        }) */
    },
    snack (message, color, ...args) {
      this.top = false
      this.bottom = false
      this.left = false
      this.right = false

      for (const loc of args) {
        this[loc] = true
      }

      this.color = color  
      this.snackMessage = message
      this.snackbar = true
    },
    async getLeadDetail() {
      if(this.$route.query.email){
        this.loading = true
        getLead(this.$route.query.email)
          .then((result) => {
            if(result.data) {
              this.lead = result.data;
              this.state = this.lead.state;
              this.contrato = this.lead.contrato;
              this.lead.marital_type = 'N/A';
              this.loading = false;
            }
          })
          .catch(err => {
            console.error('Error: ', err);
            this.loading = false;
          })
      }
    },
    async update(select_lead) {
      await updateLead(select_lead.email, select_lead)
        .then(() => {
          this.$nextTick(() => {
            this.snack('Dado(s) atualizado(s) com sucesso!','green','top')
          })
        })
        .catch(error => {
          console.error('Erro ao atualizar os dados. ', error)
          this.snack('Erro ao atualizar o(s) dado(s).','error','top')
        })
    },
  },
  computed: {

  },
  created() {
    
  },
  beforeMount() {

  },
  mounted() {
    // console.log(JSON.parse(localStorage.getItem("user")));
    // Verifica se o parametro email foi passado na URL
    if(!this.$route.query.email) {
      // Caso não tenha o paramentro email pega o ultimo usuario localmente
      this.lead = JSON.parse(localStorage.getItem("lead"));  
      if(this.lead === '' || !this.lead) {
        // Caso não tenha passado email como parametro e 
        // e não há usuário local, manda para tela inicial de cadastro
        this.$router.replace('/')
      } else {
        // Caso existe usuário local, pega o primeiro nome
        this.first_name = this.lead.name;
        this.first_name = this.first_name.split(' ')[0]
        this.desc_cont = this.lead.contrato.descCont
        this.loading = false;
      }
    } else {
      // Caso tenha passado email no parametro busca os dados do lead no banco de dados
      getLead(this.$route.query.email)
        .then((lead) => {
          this.lead = lead.data;
          this.first_name = this.lead.name;
          this.first_name = this.first_name.split(' ')[0]
          localStorage.setItem('lead', JSON.stringify(this.lead));
          this.loading = false;
        })
        .catch((error) => {
          console.error('Usuário não localizado ou erro na chamada da API!', error)
          this.$router.replace('/')
        })
    }
    // console.log(this.lead)
    const signers = [{
      email: this.lead.email,
      act: '1',
      foreign: '0',
      certificadoicpbr: '0',
      assinatura_presencial: '0'
    }]
    const location = {
      safe: "a5c41c2b-2ad1-4d9c-a7c2-b6005cdf4df5",
      folder: "a104b85c-64fc-4ca1-8812-cfbaf7d12049"
    }
    const contractCover = {
      name: this.lead.name,
      credit: this.lead.contrato.credit.toString() || '0',
      amount: this.lead.contrato.amount.toString() || '0',
      discount: this.lead.contrato.discount_total.toString() || '0',
      percentage_discount: this.lead.contrato.percentage_discount.toString() || '0',
    }
    const contract = {
      name: this.lead.name,
      cpf: this.lead.cpf,
      birthday: this.lead.birthday,
      credit: this.lead.contrato.credit.toString() || '0',
      amount: this.lead.contrato.amount.toString() || '0',
      percentage: (this.lead.contrato.percentage_power_plant * 100).toString() || '0',
      discount: this.lead.contrato.discount_total.toString() || '0',
      nationality: this.lead.nationality,
      marital_status: this.lead.marital_status,
      property_regime: this.lead.property_regime,
      profession: this.lead.profissao || this.lead.profession || '',
      document_id: this.lead.ID_document,
      dispatching_agency: this.lead.orgao_emissor,
      address: this.lead.address,
    }
    const adhesionterm = {
      name: this.lead.name,
      cpf: this.lead.cpf,
      address: this.lead.address,
    }
    const message = {
      skip_email: '0',
      workflow: '0'
    }
    // console.log(signers)
    // console.log(location)
    // console.log(contractCover)
    // console.log(contract)
    // console.log(adhesionterm)
    // console.log(message)
    // console.log(SET_CONTRACT)
    this.$apollo.mutate({
      mutation:SET_CONTRACT,
      variables: {
        signers: signers,
        location: location,
        contractCover: contractCover,
        contract: contract,
        adhesionterm: adhesionterm,
        message: message
      }
    })
      .then((result) => {
        this.loading = result.loading
        // console.log('uuid: ', result.data.createContractPF.uuid)
        // console.log('key-signer: ', result.data.createContractPF.signers)
        // eslint-disable-next-line
        d4sign.configure({
          container: 'signature-div',
          key: result.data.createContractPF.uuid,
          protocol: 'https',
          host: process.env.VUE_APP_GRAPHQL_HOST,
          signer: {
              email: this.lead.email,
              key_signer: result.data.createContractPF.signers[0].key_signer
          },
          width: '1025',
          height: '400',
          callback: function(event) {
            if (event.data === "signed") {
              alert('ASSINADO');
            }
          }
        });
      })
    
  },
  watch: {

  },
  filters: {
    /* formatStep: function (value) {
      var step_text = '';
      if (!value) return ''
      
      if (value === '0') {
        step_text = 'Sem contas'
      } else if (value === '1') {
        step_text = 'Gerando propostas'
      } else {
        step_text = 'error'
      }
      return step_text
    }, */
  }
}
</script>
<style scoped>
.zoom {
  transition: transform .2s;
  margin: 0 auto;
}

.zoom:hover {
  -ms-transform: scale(1.4); /* IE 9 */
  -webkit-transform: scale(1.4); /* Safari 3-8 */
  transform: scale(1.4); 
}

.list {
  font-weight: bold;
  font-size: 1em;
}

.header {
    font-size: 2em;
    vertical-align: -webkit-baseline-middle;
    vertical-align: middle;
    display: table-cell;
    color: #FFF;
}
.header span {
  font-size: 2em;
  color: #FFF;
  font-weight: bold;
}

.direita {
    width:95%;
    padding-left: 5%;
    text-align: left;
}
</style>
